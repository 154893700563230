import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import { FeatureSections } from '../components/global/FeatureSections';
import { FlowToContact } from '../components/global/FlowToContact';
import { HeadElement } from '../components/global/HeadElement';
import { UseCases } from '../components/global/UseCases';
import { TransmitHeader } from '../components/transmit/TransmitHeader';

function Transmit({ data }) {
   const { contentFeatures, useCases } = data;

   return (
      <>
         <HeadElement namespace="transmit" />
         <Container>
            <TransmitHeader />
         </Container>
         <FeatureSections variant="vture-light-cyan" content={contentFeatures.edges} />
         <Container>
            <UseCases content={useCases} />
            <FlowToContact variant="vture-light-cyan" />
         </Container>
      </>
   );
}

export default Transmit;

export const query = graphql`
   query ($language: String!) {
      locales: allLocale(filter: { language: { eq: $language } }) {
         edges {
            node {
               ns
               data
               language
            }
         }
      }
      contentFeatures: allMarkdownRemark(
         filter: { frontmatter: { route: { eq: "/transmit" }, type: { eq: "features" }, language: { eq: $language } } }
         sort: { fields: frontmatter___order, order: ASC }
      ) {
         edges {
            node {
               id
               html
               frontmatter {
                  icon
                  image {
                     childImageSharp {
                        gatsbyImageData(width: 700, placeholder: BLURRED)
                     }
                  }
                  imageAlt
                  isHeader
                  order
                  language
               }
            }
         }
      }
      useCases: allMarkdownRemark(
         filter: { frontmatter: { route: { eq: "/transmit" }, type: { eq: "usecases" }, language: { eq: $language } } }
         sort: { fields: frontmatter___order, order: ASC }
      ) {
         edges {
            node {
               id
               html
               frontmatter {
                  isHeader
                  order
                  title
                  language
               }
            }
         }
      }
   }
`;
